
import { defineComponent, ref, watch } from 'vue';

import { useToast } from 'primevue/usetoast';
import DataTable from '@/components/UI/DataTable/DataTable.vue';
import Column from 'primevue/column';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';

import { LotApi } from '@/classes/api/LotApi';
import { LotInbound, LotReport } from '@/interfaces/LotReport';
import { dateFilter } from '@/utils/filters';

import LotSelector from '@/components/UI/Selectors/LotSelector.vue';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  name: 'LotsReport',
  components: {
    LotSelector,
    DataTable,
    Column,
    SelectButton,
    Button,
  },
  setup() {
    const selectedLot = ref();
    const lotReport = ref<LotReport>();
    const lotMap = ref([]);
    const products = ref<LotInbound[]>([]);
    const loading = ref(false);
    const selectedReport = ref('inbound');
    const pages = ref(0);
    const page = ref(0);
    const size = ref(40);
    const totalRecords = ref(0);
    const loadedRecords = ref(0);

    const reportType = [
      { name: 'Поступило', key: 'inbound' },
      { name: 'Продано', key: 'sale' },
      { name: 'Списано', key: 'off' },
      { name: 'Остатки', key: 'exist' },
    ];

    const toast = useToast();
    const api = new LotApi();

    watch(selectedReport, (curr, old) => {
      if (curr !== old) {
        products.value = [];
      }
    })

    const reportTitle = {
      totalParts: { title: 'Всего позиций', type: 0 },
      inboundQty: { title: 'Оприходованных', type: 0 },
      inboundCost: { title: 'Себестоимость остатков', type: 1 },
      inboundPrice: { title: 'Сумма оприходованного', type: 1 },
      remainingPrice: { title: 'Цена остатков без скидки', type: 1 },
      remainingPriceWithDiscount: {
        title: 'Цена остатков со скидкой',
        type: 1,
      },
      saleQty: { title: 'Позиций продано', type: 0 },
      remainingQty: { title: 'Остаток позиций', type: 0 },
      saleAmount: { title: 'Всего продано на сумму', type: 1 },
    };

    const getFilteredDate = (date: string): string => {
      return dateFilter(date, 'datetime');
    };

    const getReport = async () => {
      if (!selectedLot.value) {
        return;
      }
      page.value = 0;
      loading.value = true;
      try {
        lotReport.value = await api.report(selectedLot.value);
        const result = await api.inboundReport(
          selectedLot.value,
          page.value + 1,
          size.value,
          selectedReport.value
        );
        products.value = result.results;
        totalRecords.value = result.rowCount;
        loadedRecords.value = result.results.length;
        pages.value = result.pageCount;
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    const formatter = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
    });

    const format = (text: number): string => {
      return formatter.format(text);
    };

    const loadMore = async () => {
      if (page.value >= pages.value) {
        return;
      }

      loading.value = true;
      try {
        page.value += 1;
        const result = await api.inboundReport(
          selectedLot.value,
          page.value + 1,
          size.value,
          selectedReport.value
        );
        products.value = [...products.value, ...result.results];
        loadedRecords.value += result.results.length;
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    return {
      page,
      pages,
      loadedRecords,
      totalRecords,
      selectedLot,
      lotMap,
      products,
      loading,
      selectedReport,
      reportType,
      lotReport,
      reportTitle,
      getReport,
      format,
      getFilteredDate,
      loadMore,
    };
  },
});
